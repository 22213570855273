<template>
    <div v-if="persistedEntity">
        <content-header
            headline="Dokument Studenten-Portal"
        >
            <router-link
                to="/tools/customerInformationAttachments"
                class="button thirdly small"
            >
                Zurück zur Übersicht
            </router-link>
        </content-header>
        <content-main>
            <div class="panel">
                <form
                    v-on:submit.prevent="submitData"
                    class="grid gap-4"
                >
                    <update-form
                        v-model="editedEntity"
                        :errors="mappedErrors"
                    />

                    <div class="text-center">
                        <button type="submit" class="button">
                            Aktualisieren
                        </button>
                    </div>
                </form>
            </div>
        </content-main>
    </div>
</template>


<script>
    import detailView from "@/../mixins/detailView";
    import UpdateForm from "@/data/CustomerInformationAttachment/UpdateForm.vue";
    import util from "../../../../../plugins/util";

    export default {
        data() {
            return {
                mappedErrors: {}
            }
        },
        components: {UpdateForm},
        mixins: [
            detailView
        ],
        methods: {
            afterError(error) {
                this.mappedErrors = util.mapConstraints(error);
            },
            getEndpoint() {
                return 'customer_information_attachments';
            }
        },
        mounted() {
            if(this.$route.params.id === 'create') {
                this.persistedEntity = {};
            }
        }
    }
</script>
